.App {
  background-color: #558eff;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.App-logo {
  height: 2em;
}

